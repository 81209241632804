@use '../utils' as *;

/* ------------------------------------------ */
/*  07 - offcanvas css  */
/* ------------------------------------------ */

// Offcanvas style
.offcanvas {
    &__close {
        button {
            height: 45px;
            width: 45px;
            @include flexbox();
            align-items: center;
            justify-content: center;
            color: var(--clr-common-white);
            font-size: 18px;
            background-color: var(--clr-theme-1);
            @include border-radius(50%);
            overflow: hidden;
            position: relative;

            i {
                color: var(--clr-common-white);
            }
        }
    }

    &__info {
        background: #191919 none repeat scroll 0 0;
        border-left: 2px solid var(--clr-theme-1);
        position: fixed;
        right: 0;
        top: 0;
        width: 400px;
        height: 100%;
        -webkit-transform: translateX(calc(100% + 80px));
        -moz-transform: translateX(calc(100% + 80px));
        -ms-transform: translateX(calc(100% + 80px));
        -o-transform: translateX(calc(100% + 80px));
        transform: translateX(calc(100% + 80px));
        -webkit-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
        -moz-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
        transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
        z-index: 99999;
        overflow-y: scroll;
        overscroll-behavior-y: contain;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }

        ;

        @media #{$xs} {
            width: 300px;
        }

        &.info-open {
            opacity: 1;
            @include transform(translateX(0));
        }
    }

    &__logo {
        width: 180px;

        @media #{$xxs} {
            width: 150px;
        }

        img {
            width: 100%;
        }
    }

    &__wrapper {
        padding: 40px 30px;

        @media #{$xs} {
            padding: 25px 20px;
        }
    }

    &__content {
        display: block;
    }

    &__top {
        padding-bottom: 00px;
    }

    &__color {
        margin-bottom: 40px;

        & h5 {
            font-size: 22px;
            margin-bottom: 15px;
        }

        & input {
            display: none;
        }

        & label {
            width: 100%;
            height: 60px;
            background-color: var(--clr-theme-1);

            &:hover {
                cursor: pointer;
            }
        }

        &-2 {
            label {
                background-color: var(--bd-theme-2);
            }
        }
    }

    &__search {
        position: relative;

        & input {
            width: 100%;
            height: 50px;
            line-height: 40px;
            padding: 0 15px;
            padding-inline-start: 45px;
            background: #0D0D0D;
            border: none;
            border-radius: 50px;
            outline: none;
            font-size: 16px;
            @include transition(0.3s);
            color: var(--clr-common-white);

            &::placeholder {
                color: var(--clr-common-placeholder);
            }

            &:focus {
                border-color: var(--clr-common-white);
            }
        }

        & button {
            position: absolute;
            top: 50%;
            inset-inline-start: 15px;
            @include transform(translateY(-50%));
            font-size: 16px;
            color: var(--clr-common-white);

            &:hover {
                color: var(--clr-theme-1);
            }
        }
    }

    &__text {
        & p {
            margin-bottom: 25px;
        }
    }

    &__contact {
        & h4 {
            font-size: 22px;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-weight: var(--bd-fw-bold);
            color: var(--clr-common-white);
        }

        & ul {
            & li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &:hover {
                    & i {
                        color: var(--clr-common-white);
                        border-color: var(--clr-theme-1);
                        background-color: var(--clr-theme-1);
                    }
                }
            }
        }

        &-icon {
            & i {
                width: 40px;
                height: 40px;
                @include flexbox();
                align-items: center;
                justify-content: space-evenly;
                text-align: center;
                border: 1px solid var(--clr-border-1);
                @include border-radius(50%);
                @include border-radius(50%);
                @include transition(.3s);
                overflow: hidden;
                color: var(--clr-common-white);
                position: relative;
                z-index: 5;
            }
        }

        &-text {
            & a {
                font-size: 16px;
                font-weight: 500;
                color: var(--clr-common-white);
                @include transition(0.3s);

                &:hover {
                    color: var(--clr-theme-1);
                }
            }
        }
    }

    &__social {

        & ul {
            & li {
                display: inline-block;

                &:not(:last-child) {
                    margin-inline-end: 5px;
                }

                & a {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    @include flexbox();
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--clr-border-1);
                    color: var(--clr-theme-1);
                    @include border-radius(50%);
                    overflow: hidden;
                    position: relative;
                    z-index: 5;
                    background-color: transparent;
                    @include transition(0.3s);

                    &:hover {
                        color: var(--clr-common-white);
                        border: 1px solid var(--clr-theme-1);
                        background-color: var(--clr-theme-1);
                    }
                }
            }
        }
    }

    &__notification {
        &-icon {
            & a {
                font-size: 14px;
                color: var(--clr-common-white);
                position: relative;

                & .notification-count {
                    position: absolute;
                    top: -4px;
                    right: -13px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 22px;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: var(--clr-theme-1);
                    @include border-radius(50%);
                    color: var(--clr-common-white);
                    text-align: center;
                }
            }
        }

        &-text {
            & p {
                margin-bottom: 0;
                font-weight: 500;
                color: var(--clr-common-white);
                font-size: 14px;

                & .notification-number {
                    color: var(--clr-theme-1);
                }

                & a {
                    color: var(--clr-theme-1);
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        background: var(--clr-common-black);
        z-index: 99998;
        top: 0;
        opacity: 0;
        visibility: hidden;
        right: 0;
        @include transition(.3s);

        &.overlay-open {
            opacity: 0.8;
            visibility: visible;
        }
    }

    .signin-area.open {
        opacity: 0.6;
        visibility: visible;
        @include transition(.3s);
    }
}

.header__hamburger {
    display: inline-flex;

    @media #{$larger,$xxxl} {
        display: none;
    }
}

.sidebar__toggle {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.app__offcanvas-overlay.overlay-open {
    @media (max-width: 992px) {
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 10;
    }
}

.offcanvas__contact-icon {
    margin-inline-end: 15px;
}

.offcanvas__user {
    .user__acount {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .user__name {
        font-size: 20px;
        font-weight: 500;
        color: var(--clr-common-white);
        margin-bottom: 0;
    }

    .user__mail {
        font-size: 14px;
        margin-bottom: 0;
    }
}

.offcanvas__btn {
    @media #{$larger,$xxxl} {
        display: none;
    }
}