/* Terms Conditions Template - Start
================================================== */

@use '../utils' as *;

.terms_conditions_content {
	padding: 40px;
	background-color: var(--clr-common-black);
	border-radius: 10px;

	@media #{$xs} {
		padding: 30px;
	}

	.warpper_title {
		font-size: 40px;
		font-weight: var(--bd-fw-sbold);
		line-height: 1.2;
		text-transform: capitalize;
		color: var(--clr-common-white);

		@media #{$xs,$sm,$lg,$md} {
			font-size: 28px;
		}

		@media #{$xs} {
			font-size: 26px;
		}
	}

	p {
		font-size: 16px;
		line-height: 26px;
		text-transform: capitalize;
		color: var(--clr-text-5);
		margin-bottom: 30px;
	}

	.info_title {
		font-size: 20px;
		color: var(--clr-text-3);
		font-weight: 500;
		margin-bottom: 20px;
		@include transition(0.3s);

		&:hover {
			color: var(--clr-theme-1);
		}
	}

	.icon_list {
		margin-bottom: 40px;
	}

	.icon_list.unordered_list_block>li:not(:last-child) {
		margin-bottom: 14px;
	}

	.icon_list .list_item_text {
		color: var(--clr-body-text);
	}

	.icon_list .list_item_icon {
		display: none;
	}

	.link {
		font-size: 16px;
		font-weight: 500;
		color: var(--clr-theme-1);
		line-height: 27px;
		text-decoration: underline;
		@include transition(0.3s);

		&:hover {
			color: #69E7CB;
		}
	}

	.author_mail {
		color: var(--clr-theme-1);
		@include transition(0.3s);

		&:hover {
			color: #69E7CB;
		}
	}
}

.nav.tabs_nav_boxed {
	padding: 30px 0;
	border-radius: 12px;
	border: 2px solid var(--clr-border-1);

	@media #{$xs,$sm,$md} {
		margin-bottom: 30px;
	}
}

.tabs_nav_boxed button {
	display: flex;
	font-size: 16px;
	font-weight: 500;
	line-height: 27px;
	padding: 10px 40px;
	position: relative;
	align-items: center;
	color: var(--clr-common-white);
}

.tabs_nav_boxed button i {
	opacity: 0;
	font-size: 10px;
	margin-right: 10px;
	line-height: 1;
	color: var(--clr-theme-1);
	transform: translateX(-10px);
	transition: .3s cubic-bezier(.5, 1, .89, 1);
}

.tabs_nav_boxed button.active i {
	opacity: 1;
	transform: translateX(0);
}

.tabs_nav_boxed button:hover,
.tabs_nav_boxed button.active {
	color: var(--clr-theme-1);
}

.terms_conditions_content .icon_list .list_item_text {
	font-size: var(--bd-fs-p);
	font-weight: var(--bd-fw-normal);
	color: var(--clr-text-5);
	margin-bottom: 30px;
	line-height: 26px;
	text-transform: capitalize;
}

.musicly_list_item {
	color: var(--clr-common-white);
	font-weight: 600;
	font-size: 16px;
	line-height: 1.2;
	list-style: none;
	transition: all 0.3s ease-out 0s;

	&:hover {
		color: var(--clr-theme-1);
	}
}

/* Terms Conditions Template - End
================================================== */