@use '../utils' as *;

/* ------------------------------------------ */
/*  24 - function-brand css  */
/* ------------------------------------------ */

.ms-fun-brand-thumb {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	overflow: hidden;
}

.ms-fun-brand-thumb img {
	width: 100%;
}

.ms-fun-brand-top {
	display: flex;
	gap: 20px;
	align-items: center;
}

.ms-fun-brand-title {
	font-weight: 500;
	color: var(--clr-text-3);
	font-size: 20px;
	@include transition(0.3s);

	&:hover {
		color: var(--clr-theme-1);
	}
}

.homeEventTitle {
	margin-bottom: "1em";
}

// .homeScreenImage {
// 	width: "100%";
// }

// @media(max-width:768px) {
// 	.homeScreenImage {
// 		height: '35em';
// 	}

// 	.homeEventTitle {
// 		font-size: 20px;
// 	}
// }

// @media(max-width:480) {
// 	.homeScreenImage {
// 		height: '20em';
// 	}

// 	.homeEventTitle {
// 		font-size: 100px;
// 	}
// }



.homeEventTitle:hover {
	color: var(--clr-common-white);

}

.ms-fun-brand-subtitle {
	color: var(--clr-text-4);
	font-size: 14px;
}

.ms-fun-brand-rating {
	position: relative;
	padding-inline-start: 15px;
	margin-inline-start: 20px;

	&::after {
		content: "";
		position: absolute;
		width: 1px;
		height: 20px;
		background: var(--clr-border-1);
		top: 5px;
		inset-inline-start: 0;

		@include rtl {
			inset-inline-start: unset;
			right: 0;
		}
	}

	i {
		color: var(--clr-bg-3);
		font-size: 12px;
		margin: 0 2px;

		&.unrate {
			color: var(--clr-border-1);
		}
	}
}

.ms-fun-brand-bottom {
	display: inline-flex;
	justify-content: space-between;
	border: 1px solid var(--clr-border-1);
	@include border-radius(30px);
	padding: 8px 14px 10px 14px;
	min-width: 200px;

	@media #{$lg, $xxs} {
		flex-direction: row;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
		@include border-radius(15px);

		.ms-fun-brand-rating {
			position: relative;
			padding-inline-start: 0;
			margin-inline-start: 0;

			&::after {
				display: none;
			}
		}
	}
}

.ms-fun-brand-location {
	a {
		@include transition(0.3s);

		i {
			margin-inline-end: 7px;

			@include rtl {
				margin-inline-end: 0;
				margin-inline-start: 7px;
			}
		}

		&:hover {
			color: var(--clr-common-white);
		}
	}
}

.ms-fun-border {
	border: 1px solid var(--clr-border-1);
	border-radius: 15px;
}

.ms-fun-brand-wrap {
	display: grid;
	grid-template-columns: auto auto auto auto;
	gap: 24px 24px;

	@media #{$xl, $lg} {
		grid-template-columns: auto auto auto;
	}

	@media #{$md} {
		grid-template-columns: auto auto;
	}

	@media #{$sm, $xs} {
		grid-template-columns: auto;
	}

}

.ms-fun-brand-wrap2 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 24px 24px;

	@media #{$xl, $lg} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media #{$md} {
		grid-template-columns: auto auto;
	}

	@media #{$sm, $xs} {
		grid-template-columns: auto;
	}

}

.ms-fun-brand-wrap3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 24px 24px;

	@media #{$xl, $lg} {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media #{$md} {
		grid-template-columns: auto auto;
	}

	@media #{$sm, $xs} {
		grid-template-columns: auto;
	}

}

.ms-fun-brand-item {
	padding: 24px;
	@include transition(0.3s);

	@media #{$xxs} {
		padding: 20px;
	}

	&:hover {
		border-color: var(--clr-common-white);
	}
}


.ms-fun-brand-bb {
	border-bottom: 1px dashed var(--clr-border-1);
}