.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@font-face {
  font-family: 'Prompt-Regular';
  src: url('/src/assets/fonts/Prompt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Oregano-Italic';
  src: url('/src/assets/fonts/Oregano-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* @font-face {
  font-family: 'MavenPro';
  src: url('/public/fonts/MavenPro-Regular.ttf')
}

.mavenPro {
  font-family: 'MavenPro'
} */
.hoverbtn {
  padding-left: "2px";
  width: "30%";
  height: "1%";
  padding-right: "2%";
  padding-top: "1%";
  padding-bottom: "1%";
  background: "#ff6107";
  color: "white";
  border-radius: "20%";
  text-align: center;
}

.hoverbtn:hover {
  background: white;
  color: #282c34
}

.nav-pills-custom .nav-link {
  color: #ff8616;
  background: #000;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #fff;
  background: #ff8616;
}

.nav-pills-custom .nav-link:hover {
  color: #fff;
  background: #ff8616;

}




/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

/* .{background:red !important;} */



.tab-pane1 {
  background: #ff8616 !important;
  color: #000 !important;
  margin-left: -30px;
}

.font-italic {
  color: #fff !important;
}

.imgslider img {
  height: 300px;
  width: 145px;
}


.why_chose_img {
  width: 15rem;
    height: 15rem;
    /* background: white; */
    /* background: linear-gradient(180deg, #f37aaac7 0%, #e6c14fa7 100%); */
    border-radius: 21%;
    /* border: 3px solid #ffb500; */
    padding: 13px;
}


.img-container img {
  width: 35vw; /* Set width auto to maintain aspect ratio */
  height: 70vh; /* Default for large screens */
}

@media (max-width: 768px) { /* For small screens */
  .img-container img {
    width: 90vw; /* Set width auto to maintain aspect ratio */
    height: 45vh; 
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;



/* langugae switch */

/* From Uiverse.io by Yaya12085 */ 
.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 9999px;
  background-color: rgba(100, 116, 139, 0.377);
  transition: all .3s ease;
}

.switch:checked {
  background-color: "#ed7551";
}

.switch::before {
  position: absolute;
  content: "";
  left: calc(1.5rem - 1.6rem);
  top: calc(1.5rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all .3s ease;
}

.switch:hover::before {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, .15)
}

.switch:checked:hover::before {
  box-shadow: 0 0 0px 8px rgba(236, 72, 153, .15)
}

.switch:checked:before {
  transform: translateX(100%);
  border-color: "#ed7551";
}