@use '../utils' as *;

/* ------------------------------------------ */
/*  14 - settings css  */
/* ------------------------------------------ */
.bd-theme {
    $self : &;

    &-settings {
        &-area {
            position: fixed;
            top: 50%;
            right: 0px;
            width: 240px;
            background-color: var(--clr-bg-5);
            border: 1px solid var(--clr-border-3);
            @include transform(translateY(-50%) translateX(100%));
            z-index: 991;
            border-bottom-right-radius: 4px;
            @include transition(0.3s);

            @include rtl {
                @include transform(translateY(-50%) translateX(-100%));
                left: 0px;
                right: auto;
            }

            &.settings-opened {
                @include transform(translateY(-50%) translateX(0%));

                #{$self} {
                    &-settings-gear {
                        opacity: 0;
                    }

                    &-settings-close {
                        opacity: 1;
                    }
                }
            }
        }

        &-open {
            position: absolute;
            top: -1px;
            inset-inline-end: 100%;

            & button {
                background-color: var(--clr-bg-5);
                border: 1px solid var(--clr-border-3);
                border-inline-end: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                font-size: 20px;
                color: var(--clr-common-white);
                position: relative;
                border-start-start-radius: 4px;
                border-bottom-left-radius: 4px;
                color: var(--clr-theme-1);
            }
        }

        &-gear {
            display: inline-block;
            -webkit-animation: spin 4s linear infinite;
            -moz-animation: spin 4s linear infinite;
            animation: spin 4s linear infinite;

            @at-root {
                @include keyframes(spin) {
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
            }
        }

        &-close {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            opacity: 0;
        }
    }

    &-header {
        &-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
            color: var(--clr-common-white);
        }
    }

    &-wrapper {
        padding: 20px 30px 30px;
    }

    &-toggle {
        text-align: center;

        &-main {
            display: inline-block;
            width: 160px;
            margin: auto;
            position: relative;
            z-index: 1;
            background-color: #f0f0f5;
            padding: 4px;
            border-radius: 20px;
        }

        &-light,
        &-dark {
            display: inline-block;
            width: calc(100% - 52%);
            height: 26px;
            line-height: 26px;
        }

        & input {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        & label {
            color: var(--clr-common-black);
            font-size: 14px;
            font-weight: 500;

            &:hover {
                cursor: pointer;
            }
        }

        & #bd-theme-toggler {
            display: none;

            &:checked+i {
                right: calc(50% - 4px);
            }
        }

        &-slide {
            position: absolute;
            top: 50%;
            right: 4px;
            @include transform(translateY(-50%));
            width: calc(100% - 50%);
            height: 26px;
            color: var(--clr-common-black);
            background-color: var(--clr-common-white);
            border-radius: 30px;
            -webkit-transform: translate3d(0, 0);
            transform: translate3d(0, 0);
            @include tp-transition(all, .2s, cubic-bezier(0.25, 1, 0.5, 1));
            z-index: -1;
        }

    }

    &-dir {
        text-align: center;

        &-main {
            display: inline-block;
            width: 160px;
            margin: auto;
            position: relative;
            z-index: 1;
            background-color: #f0f0f5;
            padding: 4px;
            border-radius: 20px;
        }

        &-ltr,
        &-rtl {
            display: inline-block;
            width: calc(100% - 52%);
            height: 26px;
            line-height: 26px;
        }

        & input {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        & label {
            color: var(--clr-common-black);
            font-size: 14px;
            font-weight: 500;

            &:hover {
                cursor: pointer;
            }
        }

        & #bd-dir-toggler {
            display: none;

            &:checked+i {
                right: calc(50% - 4px);

            }
        }

        &-slide {
            position: absolute;
            top: 50%;
            right: 4px;
            @include transform(translateY(-50%));
            width: calc(100% - 50%);
            height: 26px;
            color: var(--clr-common-black);
            background-color: var(--clr-common-white);
            border-radius: 30px;
            -webkit-transform: translate3d(0, 0);
            transform: translate3d(0, 0);
            @include tp-transition(all, .2s, cubic-bezier(0.25, 1, 0.5, 1));
            z-index: -1;
        }

    }

    &-color {
        &-item {
            &.active {
                & button {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &-btn {
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            position: relative;
            border-radius: 50px;

            &::before {
                position: absolute;
                content: '\f00c';
                font-weight: 600;
                font-family: var(--bd-ff-fontawesome);
                color: var(--clr-common-white);
                font-size: 16px;
                left: 50%;
                top: 50%;
                @include transform(translate(-50%, -50%));
                @include tp-transition();
                opacity: 0;
                visibility: hidden;
            }

            &.bd-color-settings-btn {

                &[data-color="#FF589B"] {
                    background-color: #FF589B;
                }

                &[data-color="#69E7CB"] {
                    background-color: #69E7CB;
                }

                &[data-color="#FBC21E"] {
                    background-color: #FBC21E;
                }

                &[data-color="#F7426F"] {
                    background-color: #F7426F;
                }
            }
        }

        &-input {
            margin-top: 15px;

            h6 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
                color: var(--clr-common-white);
                text-align: center;
            }

            label {
                display: inline-block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: var(--bd-theme-1);
                @include tp-transition(all);
                border-radius: 50px;

                &:hover {
                    cursor: pointer;
                }
            }

            input {
                display: none;
            }
        }
    }
}


// gsap animation css 
.msg_title {
    div {
        display: inline-block;
    }
}