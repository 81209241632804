@use '../utils' as *;

/* ------------------------------------------ */
/*  35 - genres css  */
/* ------------------------------------------ */

.ms-genres-tab-bar {
    display: flex;
    gap: 0 5px;

    span {
        width: 3px;
        height: 15px;
        background: var(--clr-border-1);
    }
}

.ms-genres-tab {
    display: flex;
    gap: 0 10px;

    @media #{$xs} {
        margin-top: 10px;
    }
}

.ms-genres-tab {
    li button {
        background: transparent !important;
        border: 1px solid var(--clr-border-1) !important;
        border-radius: 4px;
        padding: 10px;

        &.active {
            background: var(--clr-border-1) !important;
            border-radius: 4px;

            .ms-genres-tab-bar {
                span {
                    background: var(--clr-bg-1);
                }
            }
        }
    }
}

.ms-genres-select {
    .nice-select {
        background-color: transparent;
        border-radius: 0;
        border: 0;
        padding-inset-inline-start: 0;
        display: inline-block;

        .list {
            background-color: var(--clr-bg-4);
        }

        &::after {
            border-bottom: 2px solid var(--clr-common-body-text);
            border-right: 2px solid var(--clr-common-body-text);
            height: 8px;
            margin-top: -6px;
            width: 8px;
        }
    }

    .ms-nice-select {
        .current {
            font-weight: 600;
            font-size: 16px;
            color: var(--clr-text-5);
        }
    }
}

.ms-genres-text {
    color: var(--clr-text-5);
}


.ms-genres-item {
    background: rgba(24, 24, 24, 0.5);
    border: 1px solid var(--clr-border-1);
    border-radius: 15px;
    padding: 25px;

    @media #{$xxs} {
        padding: 20px;
    }
}

.genres-img-214 {
    min-width: 214px;
}

.ms-genres-flex {
    display: grid;
    align-items: center;
    grid-template-columns: 38% auto;
    gap: 30px 20px;

    @media #{$xl} {
        grid-template-columns: 44% auto;
    }

    @media #{$sm, $xs} {
        grid-template-columns: auto;
    }
}

.ms-genres-title {
    font-size: 20px;
    color: var(--clr-common-white);
    margin-bottom: 20px;
    padding-inline-end: 45px;
    @include transition(0.3s);

    &:hover {
        color: var(--clr-theme-1);
    }
}

.ms-genres-img {
    position: relative;
    @include transition(0.5s);
    width: 100%;

    img {
        @include transform(scale(1.01));
        @include transition(0.3s);
        width: 100%;
        min-height: 200px;
        object-fit: cover;
    }

    &:hover {
        img {
            @include transform(scale(1.06));
        }
    }

    .popup-video {
        opacity: 0;
        visibility: hidden;
    }
}

.ms-genres-star {
    position: absolute;
    inset-inline-end: 0;
    font-size: 20px;
    color: var(--clr-theme-1);
    border: 1px solid var(--clr-border-1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    top: -10px;

    &.active {
        background: var(--clr-theme-1);
        color: var(--clr-common-white);

        i {
            font-weight: bold;
        }
    }
}


.ms-genres-price {
    color: var(--clr-bg-4);
    font-weight: 500;
    position: absolute;
    background: var(--clr-bg-3);
    display: inline-block;
    padding: 5px 5px;
    transform: rotate(52deg);
    top: 26px;
    text-align: center;
    inset-inline-end: -46px;
    min-width: 170px;

    @include rtl {
        transform: rotate(-52deg);
    }
}

.ms-genres-video {
    width: 46px;
    height: 46px;
    display: inline-block;
    background: var(--clr-bg-1);
    line-height: 48px;
    border-radius: 50%;
    color: var(--clr-bg-4);
    text-align: center;
    position: absolute;
    bottom: 20px;
    inset-inline-start: 20px;
    padding-inset-inline-start: 4px;
    @include transition(0.3s);

    &:hover {
        background: var(--clr-theme-1);
        color: var(--clr-common-white);
    }
}


.ms-fun-brand-bottom.ms-genres-rating {
    @media #{$xl} {
        flex-direction: column;
        flex-wrap: wrap;
        @include border-radius(15px);

        .ms-fun-brand-rating {
            padding-inline-start: 0;
            margin-inline-start: 0;

            &::after {
                display: none;
            }
        }
    }
}

.ms-genres-area {
    .ms-fun-brand-rating i {
        margin: 0px 4px
    }
}


// genres details area 

.ms-genres-tab2-padding {
    padding: 35px 40px 10px 40px;

    @media #{$xxs} {
        padding: 35px 20px 10px 20px;
    }
}

.ms-genres-tab2-text {
    h2 {
        font-style: italic;
        font-weight: 500;
        font-size: 26px;
        color: var(--clr-text-5);
        line-height: 1.6;

        @media #{$sm,$xs} {
            font-size: 24px;
        }

        @media #{$xxs} {
            font-size: 20px;
        }
    }

    p {
        margin-bottom: 25px;
    }
}

.add-enquiry-btn {
    .ms-border-btn {
        i {
            color: var(--clr-theme-1);
        }

        &:hover {
            i {
                color: var(--clr-common-white);
            }
        }
    }
}

.ms-genres-review-btn {
    .ms-border-btn {
        color: var(--clr-common-body-text);
        padding: 0 28px;

        i {
            color: var(--clr-common-body-text);
        }

        &:hover {
            color: var(--clr-common-white);

            i {
                color: var(--clr-common-white);
            }
        }
    }
}

.ms-genres-audio {
    display: block;

    .jp-gui {
        width: 100%;
    }

    .jp-playlist {
        width: 100%;
    }

    .jp-playlist ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        width: 100%;
        gap: 20px 30px;
        margin-bottom: 30px;

        @media #{$xl,$md} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media #{$sm,$xs} {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .jp-playlist ul li {
        list-style: none;
    }

    .jp-playlist-item {
        position: relative;
        padding-inline-start: 45px;

        &::before {
            position: absolute;
            content: "\f04b";
            width: 30px;
            height: 30px;
            border: 1px solid #414141;
            inset-inline-start: 0;
            border-radius: 50%;
            font-family: var(--bd-ff-fontawesome);
            font-weight: 700;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }

    }

    .ms-header2-song .jp-type-playlist {
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
    }

    .jp-state-playing {
        .jp-playlist-item.jp-playlist-current::before {
            content: "\f04c";
        }
    }



    .jp-controls-holder {
        @media #{$sm,$xs} {
            flex-direction: column;
            width: 100%;
        }
    }

    .jp-gui {
        @media #{$sm,$xs} {
            flex-direction: column;
            width: 100%;
        }
    }
}

.ms-genres-review-img {
    width: 182px;
    height: 182px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.ms-genres-review-title {
    font-size: 18px;
    color: var(--clr-text-5);
    margin-bottom: 20px;
}

.ms-genres-review-grid {
    display: grid;
    grid-template-columns: 29% auto;
    gap: 20px 0;

    @media #{$xl} {
        grid-template-columns: 40% auto;
    }

    @media #{$md} {
        grid-template-columns: 36% auto;
    }

    @media #{$sm, $xs} {
        grid-template-columns: auto;
    }
}

.ms-genres-filter {
    .nice-select {
        padding-inline-end: 20px;
    }
}


.ms-fun-brand-text.lh-1 {
    padding-top: 7px;
}

.ms-genres-info-img {
    top: 0;
    bottom: 0;
    z-index: -1;
    right: 0;
    width: 100%;
}

.ms-genres-info-list {
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: 1px solid #353535;
    align-items: center;
    padding: 35px 40px;

    @media #{$xxs} {
        padding: 20px 20px;
        grid-template-columns: 40% 60%;
    }

    h6 {
        font-size: 16px;
        color: var(--clr-text-5);
        font-weight: 600;
    }

    p {
        margin-bottom: 0;
    }
}

.ms-genres-info-title {
    padding: 45px 40px;
    line-height: 1;
    margin-bottom: 0;

    @media #{$xxs} {
        padding: 25px 20px;
    }
}


.ms-social {
    a {
        color: var(--clr-border-1);
        width: 48px;
        height: 48px;
        border: 1px solid var(--clr-border-1);
        border-radius: 50%;
        display: inline-block;
        line-height: 48px;
        text-align: center;
        @include transition(0.3s);
        margin-inline-end: 20px;

        &:last-child {
            margin-inline-end: 0;
        }

        &:hover {
            color: var(--clr-common-white);
            border-color: var(--clr-common-white);
        }
    }
}

.ms-social-text {
    font-weight: 500;
    font-size: 16px;
    color: var(--clr-common-body-text);
    display: inline-block;
}

.ms-like-nav-gap {
    gap: 0 15px;
}

.ms-genres-review-content {
    @media #{$lg} {
        .ms-fun-brand-bottom {
            flex-direction: row;

            .ms-fun-brand-rating {
                padding-inline-start: 15px;
                margin-inline-start: 20px;

                &::after {
                    display: block;
                }
            }
        }
    }
}

.ms-tab-button.nav-tabs .nav-link {
    margin-bottom: 20px;
}

// Enquiry list CSS
.ms-genres-item-box {
    background: rgba(24, 24, 24, 0.5);
    border: 1px solid var(--clr-border-1);
    border-radius: 15px;
    padding: 40px;

    @media #{$xxs} {
        padding: 20px;
    }
}

.ms-genres-grid {
    display: grid;
    grid-template-columns: 213px auto;
    gap: 30px;

    @media #{$xl, $lg, $md, $sm, $xs} {
        grid-template-columns: 265px auto;
    }

    @media #{$sm, $xs} {
        grid-template-columns: auto;
    }
}

.ms-genres2-item {
    padding-bottom: 30px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.ms-genres2-content {
    padding-inline-end: 100px;

    @media #{$xl, $lg, $md, $sm, $xs} {
        padding-inline-end: 50px;
    }
}

.ms-genres2-title {
    margin-bottom: 15px;
    font-size: 20px;
    color: var(--clr-common-white);
    @include transition(0.3s);

    &:hover {
        color: var(--clr-theme-1);
    }
}


// date picker css
.ms-enquiry-date {
    input {
        background: var(--clr-bg-2);
        border: 1px solid var(--clr-border-3);
        border-radius: 32.5px;
        max-width: 235px;
        height: 65px;
        padding-inline-start: 70px;
        color: var(--clr-common-body-text);
        padding-top: 4px;


        &::-moz-placeholder {
            color: var(--clr-common-body-text);
        }

        &::placeholder {
            color: var(--clr-common-body-text);
        }
    }

    i {
        color: var(--clr-common-body-text);
        font-size: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-start: 35px;
    }
}


// Ideas Advice CSS 
.ms-ideas-item-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 55px 24px;

    @media #{$sm, $xs} {
        grid-template-columns: auto;
    }
}

.ms-ideas-img {
    overflow: hidden;

    img {
        @include transition(0.3s);
    }

    &:hover {
        img {
            @include transform(scale(1.05));
        }
    }
}

.ms-ideas-item {
    .ms-title3 {
        @include transition(0.3s);
        font-size: 19px;

        &:hover {
            color: var(--clr-theme-1);
        }
    }
}

// update
.ms-genres-nice-select {
    display: flex;
    gap: 5px;
    align-items: center;

    .ms-nice-select {

        .nice-select .list .option:hover,
        .nice-select .list .option.focus,
        .nice-select .list .option.selected.focus {
            background-color: black;
        }
    }
}

.ms-genres-nice-select-two {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media #{$sm, $xs} {
        justify-content: start;
    }
}