@use '../utils' as *;

/* ------------------------------------------ */
/*  08 - section-title css  */
/* ------------------------------------------ */
// Section title css
.section {
	&__subtitle {
		font-size: 24px;
		margin-bottom: 25px;
		text-transform: capitalize;
		display: inline-block;
		font-family: var(--bd-ff-subtitle);
		background: var(--clr-gradient-4);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		line-height: 1;
	}

	&__title {
		font-size: 40px;
		font-weight: var(--bd-fw-sbold);
		line-height: 1.2;
		text-transform: capitalize;
		color: var(--clr-common-white);

		&.two {
			@media #{$xl} {
				font-size: 35px;
			}
		}

		@media #{$sm,$xs} {
			font-size: 30px;
		}

		@media #{$xxs} {
			font-size: 28px;
		}
	}

	&__title {
		&-wrapper {
			position: relative;
			z-index: 5;

			&.text-center {
				p {
					margin: 0 auto;
				}
			}

			p {
				max-width: 575px;

				br {
					display: none;
				}
			}


			&.is-white {
				.section__back-title {
					-webkit-text-stroke-color: var(--clr-border-1);
					opacity: 0.25;
				}

				.section__subtitle {
					color: var(--clr-common-white);
				}

				.section__title {
					color: var(--clr-common-white);
				}
			}

			&.is-center {
				.section__back-title {
					transform: translate(-50%, -50%);
					left: 50%;
					top: 50%;
				}
			}

			&.is-left {

				.section__subtitle,
				.section__title {
					margin-inline-start: 91px;

					@media #{$xs,$sm,$md,$lg} {
						margin-inline-start: 0px;
					}
				}
			}

			p {
				@media #{$xl, $lg, $md, $sm, $xs} {
					br {
						display: none;
					}
				}
			}
		}
	}
}

.ms-title2 {
	font-size: 50px;

	@media #{$md,$sm,$xs} {
		font-size: 40px;
	}

	@media #{$xxs} {
		font-size: 34px;
	}
}

.ms-title3 {
	font-size: 20px;
	line-height: 1.4;
}

.ms-title4 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.3;
	color: var(--clr-common-white);
}

.ms-title5 {
	font-weight: 600;
	font-size: 30px;

	@media #{$xxs} {
		font-size: 22px;
	}

	a {
		@include transition(0.3s);

		&:hover {
			color: var(--clr-theme-1);
		}
	}
}

.animated-underline {
	position: relative;
	z-index: 1;
	padding: 0;
	display: inline-block;
	background: none;
	text-decoration: none;
	color: inherit;

	@media #{$xxs} {
		display: initial;
	}
}

.animated-underline::before {
	content: '';
	position: absolute;
	width: 0;
	height: 6px;
	background-color: var(--clr-theme-3);
	bottom: 8px;
	inset-inline-start: 0;
	z-index: -1;

	@media #{$md} {
		height: 4px;
		bottom: 5px;
	}

	@media #{$sm,$xs} {
		height: 3px;
		bottom: 4px;
	}

	@media #{$xxs} {
		width: 50% !important;
		inset-inline-start: 0;
		right: 0;
		margin: 0 auto;
	}
}

.animated-underline.active::before,
.parent:hover .animated-underline::before {
	animation: width-animation 0.5s forwards;
}

.ms-title-border {
	position: relative;
	display: inline;
	background-image: -webkit-gradient(linear, left top, right top, from(var(--clr-theme-1)), to(var(--clr-theme-1)));
	background-image: -webkit-linear-gradient(left, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
	background-image: -o-linear-gradient(left, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
	background-image: linear-gradient(to right, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
	background-size: 0px 2px;
	background-position: 0px 95%;
	-webkit-transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	-o-transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	padding: 0.7% 0px;
	background-repeat: no-repeat;
	color: inherit;
	@include transition(0.5s);

	&:hover {
		background-size: 100% 2px;
		color: var(--clr-common-white);
	}
}


// Text stroke color 
.ms-text-stroke {
	font-size: 100px;
	font-weight: 700;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--clr-bg-2);
	line-height: 1;
	display: inline-block;
	@include transition(0.3s);

	&:hover {
		-webkit-text-fill-color: var(--clr-bg-2);
	}
}


// Text Color CSS 
.ms-text1 {
	color: var(--clr-bg-1);
}

.white-text {
	color: var(--clr-common-white);
}

.ms-text2 {
	color: var(--clr-text-6);
}


// text style css 
.capitalize {
	text-transform: capitalize;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}