@use "../utils" as *;
/* ------------------------------------------ */
/*  13 - preloader css  */
/* ------------------------------------------ */

#preloader {
	background-color: var(--clr-common-body);
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999999;
}

.line-loader {
	width: 190px;
	height: 100px;
	margin: 50px auto 0;
	position: relative;
	display: flex;
	justify-content: space-between;

	@media #{$xxs} {
		width: 100px;
		height: 70px;
	}
}

.line-loader .line {
	background-image: linear-gradient(180deg, var(--clr-theme-1) 0%, var(--clr-theme-2) 50%, #F40862 100%);
	width: 3px;
	height: 100%;
	display: inline-block;
	margin: 0 3px;
	transform-origin: bottom;
	animation: dance 1.6s ease-in-out infinite;
	position: relative;
	z-index: 999999;
}

.line-loader .line:nth-child(2) {
	animation-delay: 0.1s;
}

.line-loader .line:nth-child(3) {
	animation-delay: 0.2s;
}

.line-loader .line:nth-child(4) {
	animation-delay: 0.3s;
}

.line-loader .line:nth-child(5) {
	animation-delay: 0.4s;
}

.line-loader .line:nth-child(6) {
	animation-delay: 0.5s;
}

.line-loader .line:nth-child(7) {
	animation-delay: 0.6s;
}

.line-loader .line:nth-child(8) {
	animation-delay: 0.7s;
}

.line-loader .line:nth-child(9) {
	animation-delay: 0.8s;
}

.line-loader .line:nth-child(10) {
	animation-delay: 0.9s;
}

.line-loader .line:nth-child(11) {
	animation-delay: 1s;
}

.line-loader .line:nth-child(12) {
	animation-delay: 1.1s;
}

.line-loader .line:nth-child(13) {
	animation-delay: 1.2s;
}

.line-loader .line:nth-child(14) {
	animation-delay: 1.3s;
}

.line-loader .line:nth-child(15) {
	animation-delay: 1.4s;
}

@keyframes dance {

	0%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.3);
	}
}