/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.form-control {
  @apply bg-white dark:bg-slate-900 transition duration-300 ease-in-out border border-slate-200 dark:border-slate-700 dark:text-slate-300 focus:ring-1 focus:ring-slate-600 dark:focus:ring-slate-900 focus:outline-none focus:ring-opacity-90 rounded placeholder:text-slate-400 text-slate-900 text-sm px-3 placeholder:font-normal dark:placeholder:text-slate-400 block w-full;
}

body {
  margin: 0;
  /* font-family: 'MyCustomFont', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}