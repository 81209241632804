@use '../utils' as *;

/* ------------------------------------------ */
/*  16 - menu css  */
/* ------------------------------------------ */

.main-menu {
    @media #{$lg} {
        padding-inset-inline-start: 30px;

        @include rtl {
            padding-inset-inline-start: 0;
            padding-right: 30px;
        }
    }

    & ul {
        & li {
            position: relative;
            list-style: none;
            display: inline-block;
            margin-inline-end: 32px;


            @media #{$lg} {
                margin-inline-end: 25px;
            }

            &:last-child {
                margin-inline-end: 0;
            }

            & a {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: var(--clr-common-white);
                // padding: 50px 0;
                text-align: left;
                text-transform: capitalize;
                line-height: 20px;

                @include transition(.3s);

                @include rtl {
                    text-align: right;
                }
            }

            &.has-dropdown {
                &>a {
                    position: relative;

                    &::after {
                        content: '\f107';
                        @include transform(translateY(1px));
                        font-size: 14px;
                        color: var(--clr-common-white);
                        font-family: var(--bd-ff-fontawesome);
                        font-weight: 400;
                        margin-inline-start: 5px;
                        display: inline-block;
                        @include transition(.3s);

                    }
                }
            }

            &.has-mega-menu {
                position: static;
            }

            /* submenu css start */
            & .submenu {
                position: absolute;
                top: 100%;
                inset-inline-start: 0;
                min-width: 250px;
                z-index: 99;
                visibility: hidden;
                opacity: 0;
                transform-origin: top center;
                min-width: 250px;
                padding: 25px;
                background: var(--clr-bg-7);
                @include transform(scaleY(0));
                @include transition(.3s);
                @include box-shadow(0px 20px 30px rgba(3, 4, 28, 0.1));
                border-end-start-radius: 10px;
                border-end-end-radius: 10px;
                filter: drop-shadow(0px -1px 0 var(--clr-theme-1));

                @include rtl {
                    inset-inline-start: auto;
                    right: 0;
                }

                & li {
                    display: block;
                    width: 100%;
                    margin-inline-end: 0;
                    margin-bottom: 13px;
                    color: var(--clr-common-white);

                    &:last-child {
                        margin-bottom: 0 !important;
                    }

                    &.has-dropdown {
                        &>a {
                            &::after {
                                position: absolute;
                                top: 50%;
                                inset-inline-end: 10px;
                                @include transform(translateY(-50%) rotate(-90deg));
                                color: var(--clr-common-white);

                            }
                        }
                    }

                    & a {
                        position: relative;
                        z-index: 11;
                        color: var(--clr-common-white);
                        font-weight: 400;
                        font-size: 16px;
                        width: 100%;
                        padding: 0;
                    }

                    & .submenu {
                        inset-inline-start: 100%;
                        top: 0;
                        visibility: hidden;
                        opacity: 0;

                        @include rtl {
                            inset-inline-start: auto;
                            right: 100%;
                        }
                    }

                    &:hover {
                        &>a {
                            color: var(--clr-theme-1);
                            letter-spacing: 1px;

                            &::after {
                                color: var(--clr-theme-1);
                            }
                        }

                        &>.submenu {
                            @include transform(translateY(1));
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            /* mega menu start */
            & .mega-menu {
                position: absolute;
                top: 100%;
                inset-inline-start: 0;
                padding: 30px 40px;
                box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
                visibility: hidden;
                opacity: 0;
                @include transform(scaleY(0));
                transform-origin: top center;
                @include transition(.3s);
                z-index: 99;
                background: var(--clr-bg-7);
                border-end-start-radius: 10px;
                border-end-end-radius: 10px;
                filter: drop-shadow(0px -1px 0 var(--clr-theme-1));


                & li {
                    float: left;
                    width: 25%;
                    margin-inline-end: 0;
                    text-align: left;

                    @include rtl {
                        float: right;
                        margin-inline-start: 0;
                        text-align: right;
                    }

                    & .mega-menu-title {
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: var(--clr-common-white);
                        cursor: text;
                        padding: 0;
                        display: inline-block;
                        margin-bottom: 20px;

                        &::before {
                            display: none;
                        }
                    }

                    & ul {
                        & li {
                            width: 100%;
                            float: none;
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            & a {
                                font-weight: 400;
                                font-size: 15px;
                                color: var(--clr-common-white);
                                padding: 0;
                            }
                        }
                    }

                    &:hover {
                        &>.mega-menu-title {
                            color: var(--clr-theme-1);
                            padding-inset-inline-start: 0;

                            @include rtl {
                                padding-right: 0;
                            }
                        }

                        &>a {
                            color: var(--clr-theme-1);
                            letter-spacing: 1px;
                        }
                    }
                }
            }

            &:hover {
                &>a {
                    color: var(--clr-theme-1);

                    &::after {
                        color: var(--clr-theme-1);
                    }
                }

                &>.submenu {
                    visibility: visible;
                    opacity: 1;
                    @include transform(scaleY(1));
                }

                & .mega-menu {
                    visibility: visible;
                    opacity: 1;
                    @include transform(scaleY(1));
                }
            }
        }
    }

    .has-dropdown.menu-last {
        .submenu {
            right: 0;
            inset-inline-start: auto;
        }
    }
}

#header-sticky.sticky {
    .ms-border2 {
        border-color: transparent;
    }
}

.link {
    color: #ed7551 !important;
    // text-decoration: underline;
}