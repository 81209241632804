@use '../utils' as *;

@media #{$xxl} {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px;
    }
}

@media #{$xl} {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

@media #{$lg} {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media #{$md} {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media #{$sm} {

    .container,
    .container-sm {
        max-width: 540px;
    }
}

.overlay-container {
    position: relative;
}

.overlay-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Adjust the alpha value for transparency */
    z-index: 1;
}

.overlay-container img {
    position: relative;
    z-index: 0;
}

.overlay-container .position-absolute {
    z-index: 2;
}