@use '../utils' as *;

.list-steps {
    display: flex;
    width: 100%;
    margin: 30px 0px;
    min-width: 768px;

    .item-step {
        &.line-active {
            &::before {
                background-color: #6f4065;
            }
        }

        width: 50%;
        position: relative;

        &::before {
            height: 10px;
            content: "";
            width: 100%;
            z-index: 1;
            position: absolute;
            left: 1px;
            top: 33px;
            margin-top: -5px;
            background-color: #ddd;
        }

        &:last-child {
            &::before {
                display: none;
            }
        }

        .rounded-step {
            position: relative;
            z-index: 2;

            .icon-step {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: #ddd;
                margin-bottom: 20px;

                &.step-1 {
                    background-image: url(../../../assets/img/shiping/Order-Placed.svg);
                    background-repeat: no-repeat;
                    background-position: center;

                    &.active {
                        background-image: url(../../../assets/img/shiping/Order-Placed.svg);
                        background-color: var(--clr-theme-1);
                        ;
                        color: #ddd;
                    }
                }

                &.step-2 {
                    background-image: url(../../../assets/img/shiping/Production.svg);
                    background-repeat: no-repeat;
                    background-position: center;

                    &.active {
                        background-image: url(../../../assets/img/shiping/Production.svg);
                        background-color: var(--clr-theme-1);
                        ;
                    }
                }

                &.step-3 {
                    background-image: url(../../../assets/img/shiping/Shipping.svg);
                    background-repeat: no-repeat;
                    background-position: center;

                    &.active {
                        background-image: url(../../../assets/img/shiping/Shipping.svg);
                        background-color: var(--clr-theme-1);
                        ;
                        color: #ddd;
                    }
                }

                &.step-4 {
                    background-image: url(../../../assets/img/shiping/Delivered.svg);
                    background-repeat: no-repeat;
                    background-position: center;

                    &.active {
                        background-image: url(../../../assets/img/shiping/Delivered.svg);
                        background-color: var(--clr-theme-1);
                        ;
                        color: #ddd;
                    }
                }
            }
        }
    }
}

// order tracking form

.form-tracking .form-control {
    height: 54px;
}

.form-control {
    border: 1px solid #dde4f0 !important;
}

.form-control {
    display: block;
    max-width: 400px;
    width: 400px;

    @media #{$xs,$sm} {
        max-width: 250px;
        width: 100%;
    }

    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-tracking .box-button {
    width: 10%;
    min-width: 185px;
    max-width: 185px;
}

.form-group {
    margin-bottom: 15px;
}

.btn.btn-buy:hover {
    border: 1px solid #4e97fd;
    background-color: #ffffff;
    color: #425a8b;
}

.btn.btn-buy {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #ffffff;
    border: 1px solid #8c9ec5;
    width: 100%;

    @media #{$xs,$sm} {
        width: 150px;
    }

    text-align: center;
    padding: 7px 22px;
    background-color: #4e97fd;
}

.form-tracking .btn-buy {
    height: 54px;
    line-height: 34px;
}

.form-tracking .box-input {
    width: 100%;
    margin-right: 15px;
}

.tracking-search-wrapper {
    display: flex;
    justify-content: center;
}

.dynamic-margin {
    margin-bottom: 350px;
}

button.delivery_status_btn {
    border: var(--clr-theme-1);
    background-color: var(--clr-theme-1);
    border-radius: 30px;
    padding: 3px 20px;
    color: #fff;
}

.table-responsive {
    .rounded-step {
        .step-text {
            color: var(--clr-text-5);
            text-transform: capitalize;
            margin-bottom: 22px;
            font-size: var(--bd-fs-p);
            font-weight: var(--bd-fw-normal);
            line-height: 26px;
        }

        .time-stamp {
            font-weight: 500;
            color: var(--clr-text-3);
            font-size: 20px;
            @include transition(0.3s);

            &:hover {
                color: var(--clr-theme-1);
            }
        }
    }
}