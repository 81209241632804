@use '../utils' as *;

/* ------------------------------------------ */
/*  25 - event css  */
/* ------------------------------------------ */
.ms-event-active .swiper-pagination {
	margin-top: 50px;
	position: inherit;
}

.ms-event-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	background: var(--clr-bg-2);
	opacity: 0.95;
	border-radius: 15px;
}

.ms-event-title {
	color: var(--clr-common-white);
	font-size: 20px;
	margin-bottom: 20px;
	text-transform: capitalize;
	@include transition(0.3s);

	&:hover {
		color: var(--clr-theme-1);
	}
}

.ms-event-text {
	color: var(--clr-text-5);
	text-transform: capitalize;
	margin-bottom: 22px;
}

.ms-event-inner {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;
}

.ms-event-location {
	position: relative;
	padding-inline-end: 30px;
	margin-inline-end: 30px;

	a {
		@include transition(0.3s);
	}

	&:hover {
		a {
			color: var(--clr-common-white);
		}
	}
}

.ms-event-location::after {
	content: '';
	height: 14px;
	width: 2px;
	background: var(--clr-common-body-text);
	inset-inline-end: 0;
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
}

.ms-event-location a i {
	font-size: 18px;
	margin-inline-end: 6px;

	@include rtl {
		margin-inline-end: 0;
		margin-inline-start: 6px;
	}
}

.ms-event-item-top {
	padding-bottom: 30px;
	border-bottom: 1px solid var(--clr-border-1);
	margin-bottom: 30px;
}

.ms-event-bg {
	background: var(--clr-bg-2);
	opacity: 0.95;
	border-radius: 15px;
	padding: 40px 40px 25px;

	@media #{$xxs} {
		padding: 20px 20px 15px;
	}
}

.ms-event-play {
	border-radius: 15px;
	overflow: hidden;

	&-overlay {
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		background: var(--clr-common-black);
		opacity: 0.65;
		border-radius: 15px;
		width: 100%;
		height: 100%;
	}

	&-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		animation: ms-pulse 2s infinite;
	}
}


// Event 2 css 
.ms-event2-area.include__bg {
	background-position: center top;
}

.ms-event2-item {
	background: var(--clr-bg-4);
	border-radius: 15px;
	border: 1px solid var(--clr-common-white)54;
	padding: 26px 30px;
	@include transition(0.3s);

	@media #{$xxs} {
		padding: 20px 20px;
	}

	&:hover {
		border-color: var(--clr-common-white)80;
		@include transform(translateY(-3px));
	}
}

// Event 3 css 

.ms-event3-img {
	img {
		transform: scale(1.03);
		min-height: 496px;
		width: 100%;
		@include transition(0.3s);
		object-fit: cover;
	}
}

.ms-event3-date {
	position: absolute;
	font-weight: 600;
	font-size: 20px;
	color: var(--clr-common-white);
	top: 35px;
	inset-inline-start: 30px;
	font-family: var(--bd-ff-heading);
	z-index: 3;

	@media #{$xxs} {
		top: 20px;
		inset-inline-start: 20px;
	}
}

.ms-event3-content {
	position: absolute;
	bottom: 0;
	inset-inline-start: 0;
	padding: 30px;
	z-index: 3;

	@media #{$xxs} {
		padding: 20px;
	}
}

.ms-event3-location {
	background: var(--clr-common-white);
	border-radius: 100px;
	padding: 0 20px;
	height: 40px;
	display: inline-block;
	line-height: 40px;
	margin-bottom: 20px;

	i {
		margin-inline-end: 5px;
	}
}

.ms-event3-title {
	font-size: 20px;
	color: var(--clr-common-white);
	line-height: 1.6;

	@media #{$xxs} {
		font-size: 18px;
	}

	&.ms-title-border {
		position: relative;
		display: inline;
		background-image: -webkit-gradient(linear, left top, right top, from(var(--clr-common-white)), to(var(--clr-common-white)));
		background-image: -webkit-linear-gradient(left, var(--clr-common-white) 0%, var(--clr-common-white) 100%);
		background-image: -o-linear-gradient(left, var(--clr-common-white) 0%, var(--clr-common-white) 100%);
		background-image: linear-gradient(to right, var(--clr-common-white) 0%, var(--clr-common-white) 100%);

		&:hover {
			background-size: 100% 2px;
			color: var(--clr-common-white);
		}
	}
}

.ms-event3-img {
	.ms-overlay10 {
		@include transition(0.3s);
	}

	&:hover {
		.ms-overlay10 {
			background: linear-gradient(180deg, rgba(255, 193, 7, 0.24) 0%, var(--clr-theme-1) 100%);
		}

		img {
			@include transform(scale(1.1));
		}
	}
}

.ms-social2 {
	display: flex;
	gap: 10px 20px;

	a {
		color: var(--clr-border-1);
		font-size: 20px;
		@include transition(0.3s);

		&:hover {
			color: var(--clr-common-white);
		}
	}
}

.ms-tag-title {
	color: var(--clr-common-white);
	font-size: 16px;
	font-weight: 600;
}

.ms-event-dimg {
	img {
		min-height: 200px;
		object-fit: cover;
	}
}

.ms-event-dtitle {
	font-size: 24px;
	color: var(--clr-common-white);
	line-height: 1.5;

	@media #{$xxs} {
		font-size: 20px;
	}
}

.ms-tags {
	display: flex;
	gap: 10px 20px;

	a {
		color: var(--clr-text-5);
		font-weight: 500;
		@include transition(0.3s);

		&:hover {
			color: var(--clr-common-white);
		}
	}
}

.ms-blockquote {
	blockquote {
		background: var(--clr-bg-2);
		@include border-radius(15px);
		padding: 35px 40px;

		@media #{$xxs} {
			padding: 20px 20px;
		}

		i {
			color: var(--clr-theme-1);
			font-size: 40px;
			display: inline-block;
			margin-bottom: 25px;
		}

		h4 {
			color: var(--clr-text-5);
			font-weight: 600;
			font-size: 20px;
			line-height: 1.5;
			margin-bottom: 25px;

			@media #{$xxs} {
				font-size: 18px;
			}
		}

		span {
			color: var(--clr-bg-1);
			font-weight: 600;
			font-family: var(--bd-ff-heading);
		}
	}
}


.ms-event-sidebar {
	border: 1px solid var(--clr-border-1);
	border-radius: 15px;
	padding: 60px 30px 25px 30px;
}

.ms-event-sidebar-title {
	color: var(--clr-bg-1);
	font-size: 24px;
	margin-bottom: 40px;
}

.ms-event-title2 {
	font-size: 20px;
	color: var(--clr-common-white);
	margin-bottom: 15px;
}

.ms-event-sidebar-inner {
	margin-bottom: 30px;
}

.ms-event-sidebar-item {
	border-bottom: 1px solid var(--clr-border-1);
	margin-bottom: 35px;

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
	}
}

.ms-event-stext {
	display: block;
	margin-bottom: 20px;

	a {
		@include transition(0.3s);

		&:hover {
			color: var(--clr-theme-1);
		}
	}
}

.ms-event-dots ul {
	@media #{$xxs} {
		display: flex;
		gap: 20px;
		margin-top: 25px;
	}
}