@use '../utils' as *;

/* ------------------------------------------ */
/*  28 - news css  */
/* ------------------------------------------ */

.ms-news-item {
	border-radius: 15px;
	overflow: hidden;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--clr-bg-4) 100%);

	.ms-news-thumb {
		img {
			@include transition(2s);

			@media #{$sm,$xs} {
				max-height: 600px;
			}
		}
	}

	&:hover {
		.ms-news-thumb {
			img {
				@include transform(scale(1.2));
			}
		}
	}
}

.ms-news-position {
	bottom: 0;
	padding: 36px;
	z-index: 1;

	@media #{$md} {
		padding: 25px;
	}

	@media #{$xxs} {
		padding: 20px;
	}
}

.ms-news-cat a {
	position: absolute;
	background: var(--clr-common-white);
	border-radius: 100px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--clr-text-10);
	padding: 5px 20px;
	top: 35px;
	inset-inline-start: 35px;
	z-index: 2;
	@include transition(0.3s);

	&:hover {
		background: var(--clr-theme-1);
		color: var(--clr-common-white);
	}
}

.ms-news-overlay {
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--clr-bg-4) 100%);
	z-index: 1;

	@media #{$lg,$md,$sm,$xs} {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--clr-bg-4) 110%);
	}
}

.ms-news-title {
	font-size: 20px;
	color: var(--clr-common-white);
	font-weight: 500;
	line-height: 1.5;
	@include transition(0.3s);

	a {
		position: relative;
		display: inline;
		background-image: -webkit-gradient(linear, left top, right top, from(var(--clr-theme-1)), to(var(--clr-theme-1)));
		background-image: -webkit-linear-gradient(left, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
		background-image: -o-linear-gradient(left, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
		background-image: linear-gradient(to right, var(--clr-theme-1) 0%, var(--clr-theme-1) 100%);
		background-size: 0px 2px;
		background-position: 0px 95%;
		-webkit-transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
		-o-transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
		transition: background-size 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
		padding: 0.7% 0px;
		background-repeat: no-repeat;
		color: inherit;
		@include transition(0.5s);

		&:hover {
			background-size: 100% 2px;
		}
	}
}


.ms-news-text {
	text-transform: capitalize;
	color: var(--clr-text-5);
}

.ms-news-meta {
	ul {
		li {
			display: inline-block;
			list-style: none;
			margin-inline-end: 8px;
			padding-inline-end: 18px;
			position: relative;

			&:hover {
				a {
					color: var(--clr-common-white);
				}
			}

			&:last-child {
				margin-inline-end: 0;
				padding-inline-end: 0;

				&::after {
					display: none;
				}
			}

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				width: 4px;
				height: 4px;
				background: var(--clr-common-body-text);
				border-radius: 50%;
				transform: translateY(-50%);
			}
		}
	}
}


// news 2 css 
.ms-news2-cat {
	background: var(--clr-common-white);
	border-radius: 100px;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	color: var(--clr-text-10);
	padding: 5px 20px;
	@include transition(0.3s);

	&:hover {
		background: var(--clr-theme-1);
		color: var(--clr-common-white);
	}
}


// news 3 css 

.ms-news3-active.swiper-slide {
	width: auto;
}

.ms-news3-active .swiper-wrapper {
	-webkit-transition-timing-function: linear !important;
	transition-timing-function: linear !important;
	position: relative;
}

.ms-news3-img {
	width: 300px;
	height: 225px;
}

.ms-news3-title {
	font-size: 20px;
	font-weight: 500;
	color: var(--clr-common-white);
	margin-bottom: 30px;
	line-height: 1.5;

	&:hover {
		color: var(--clr-bg-1);
	}
}

.ms-news3-cat {
	display: inline-block;
	background: var(--clr-bg-4);
	@include border-radius(100px);
	color: var(--clr-common-body-text);
	font-size: 14px;
	font-weight: 500;
	padding: 0 20px;
	height: 32px;
	line-height: 32px;
	margin-bottom: 30px;
	@include transition(0.3s);

	&:hover {
		color: var(--clr-common-white);
	}
}

.ms-news3-img {
	width: 325px;
	height: 225px;
	position: absolute;
	top: 50%;
	inset-inline-start: 125px;
	@include transition(0.3s);
	@include transform(scale(0) translateY(-50%));
	transform-origin: top;
	z-index: 1;
}

.ms-news3-item {
	padding: 40px 0;
	margin: 0 45px;

	@media #{$xxl, $xl, $lg, $md, $sm, $xs} {
		margin: 0 20px;
	}

	&:hover {
		.ms-news3-img {
			@include transform(scale(1) translateY(-50%));
		}
	}
}


// news 2 css 
.ms-news-box {
	border: 1px solid var(--clr-border-1);
	border-radius: 15px;
	padding: 30px;

	@media #{$xs} {
		padding: 30px 20px;
	}
}

.ms-news-meta {
	span {
		position: relative;
		padding-inline-end: 20px;
		margin-inline-end: 13px;

		@media #{$xxs} {
			font-size: 14px;
		}

		a {
			@include transition(0.3s);
		}

		&:hover {
			a {
				color: var(--clr-common-white);
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			background: var(--clr-common-body-text);
			width: 4px;
			height: 4px;
			border-radius: 50%;
			transform: translateY(-50%);

			@include rtl {
				right: -22px;
			}
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}

.ms-news-by {
	color: var(--clr-common-white);
	font-weight: 500;
	margin-inline-end: 10px;
	display: inline-block;
}

.ms-news-title2 {
	line-height: 1.3;
	color: var(--clr-common-white);
	@include transition(0.3s);
	font-weight: 600;
	font-size: 24px;

	@media #{$xxs} {
		font-weight: 500;
		font-size: 20px;
	}

	&:hover {
		color: var(--clr-theme-1);
	}
}

.ms-news-img {
	position: relative;

	img {
		@include transition(0.3s);
		width: 100%;
	}

	.ms-news-cat a {
		background: var(--clr-common-white);
		color: var(--clr-theme-1);
		bottom: 30px;
		inset-inline-start: 30px;
		top: auto;

		@media #{$xs} {
			bottom: 20px;
			inset-inline-start: 20px;
		}

		&:hover {
			background-color: var(--clr-theme-1);
			color: var(--clr-common-white);
		}
	}

	&:hover {
		img {
			@include transform(scale(1.05));
		}
	}
}

.ms-news-video {
	width: 55px;
	height: 55px;
	display: inline-block;
	background: var(--clr-bg-1);
	line-height: 55px;
	position: absolute;
	top: 50%;
	z-index: 4;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	border-radius: 50%;
	color: var(--clr-bg-4);
	@include transition(0.3s);

	&:hover {
		background-color: var(--clr-theme-1);
		color: var(--clr-common-white);
	}
}

.ms-news-widget-title {
	font-weight: 600;
	font-size: 20px;
	color: var(--clr-common-white);
	margin-bottom: 38px;

}

.ms-news-sidebar {
	border: 1px solid var(--clr-border-1);
	border-radius: 15px;
	padding: 60px 30px;

	@media #{$xxs} {
		padding: 30px 20px;
	}
}

.ms-news-widget {
	ul {
		li {
			margin-bottom: 15px;
			list-style: none;
			position: relative;
			padding-inline-start: 18px;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				inset-inline-start: 0;
				@include transform(translateY(-50%));
				width: 6px;
				height: 6px;
				background: var(--clr-common-body-text);
				border-radius: 50%;
			}

			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: var(--clr-text-5);
				font-size: 16px;
				@include transition(0.3s);
				text-transform: capitalize;

				&:hover {
					color: var(--clr-theme-1);
					letter-spacing: 0.5px;
				}
			}
		}
	}
}

.ms-dot-none {
	ul {
		li {
			padding-inline-start: 0;

			&::after {
				display: none;
			}
		}
	}
}


.widget-post-title {
	font-size: 16px;
	color: var(--clr-text-5);
	line-height: 1.5;

	a {
		@include transition(0.3s);
	}

	&:hover {
		a {
			color: var(--clr-theme-1)
		}
	}
}

.rc-meta.widget-post-meta {
	margin-bottom: 8px;
}

.rc-thumb.mr-20 {
	min-width: 75px;

	img {
		width: 100%;
		border-radius: 5px;
	}
}

.ms-news-widget-content {
	.rc-post {
		margin-bottom: 30px;

		.rc-thumb {
			img {
				@include transition(0.3s);
			}
		}

		&:hover {
			.rc-thumb {
				img {
					@include transform(scale(1.05));
				}
			}
		}
	}
}


.tagcloud a {
	font-size: 16px;
	color: var(--clr-text-5);
	font-weight: 500;
	border: 1px solid var(--clr-border-1);
	border-radius: 30px;
	display: inline-block;
	padding: 5px 22px;
	margin-bottom: 10px;
	margin-inline-end: 5px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(96.81deg, var(--clr-theme-1) 0%, var(--clr-bg-3) 100%);
		border-radius: 30px;
		opacity: 0;
		visibility: visible;
		@include transition(0.3s);
		z-index: -1;
	}

	&:hover {
		color: var(--clr-common-white);

		&::before {
			opacity: 1;
			visibility: visible;
		}
	}
}

.ms-news-widget {
	margin-bottom: 65px;

	&:last-child {
		margin-bottom: 0;
	}
}

.ms-purchase-btn {
	font-weight: 600;
	font-size: 16px;
	border: 1px solid var(--clr-common-white);
	border-radius: 30px;
	height: 60px;
	display: inline-block;
	line-height: 60px;
	padding: 0 40px;
	color: var(--clr-common-white);
	@include transition(0.3s);

	@media #{$xs} {
		padding: 0 20px;
	}

	&:hover {
		border-color: var(--clr-theme-1);
		background-color: var(--clr-theme-1);
		color: var(--clr-common-white);
	}
}

.ms-news-widget-banner-content {
	text-align: center;
	margin: auto;
	inset-inline-start: 0;
	right: 0;
	bottom: 30px;
	padding: 0 30px;

	@media #{$xs} {
		bottom: 10px;
	}

	p {
		font-size: 22px;
		line-height: 1.6;
		color: var(--clr-common-white);
		margin-bottom: 35px;
	}
}


// news details css 
.ms-news-title3 {
	font-weight: 600;
	font-size: 24px;
	color: var(--clr-common-white);
	line-height: 1.3;

	@media #{$xxs} {
		font-weight: 500;
		font-size: 20px;
	}
}


// news comment css 
.md-author-area {
	background: var(--clr-bg-2);
	border-radius: 15px;
	padding: 44px 30px;
	display: grid;
	grid-template-columns: 162px auto;
	gap: 30px;
	align-items: center;

	@media #{$xs} {
		grid-template-columns: auto;
	}

	@media #{$xxs} {
		padding: 25px 20px;
	}
}


.ms-author-content {
	h3 {
		font-size: 20px;
		color: var(--clr-common-white);
		margin-bottom: 20px;

		a {
			@include transition(0.3s);

			&:hover {
				color: var(--clr-theme-1);
			}
		}
	}

	p {
		margin-bottom: 0;
	}
}

.ms-comment-list {
	display: grid;
	grid-template-columns: 120px auto;
	gap: 30px;
	align-items: center;

	@media #{$xs} {
		grid-template-columns: auto;
	}
}

.ms-reply {
	float: right;
	overflow: hidden;
	margin-top: -5px;

	a {
		@include transition(0.3s);
	}

	@include rtl {
		float: left;
	}

	&:hover {
		a {
			color: var(--clr-theme-1);
		}
	}
}

.ms-comment-title {
	h3 {
		font-size: 16px;
		color: var(--clr-common-white);
		margin-bottom: 8px;
	}

	p {
		margin-top: 16px;
		padding-inline-end: 60px;

		@media #{$xs} {
			padding-inline-end: 30px;
		}
	}
}

.ms-comment-list {
	padding-bottom: 40px;
	margin-bottom: 40px;

	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: 0;
	}
}


.ms-comment-img {
	width: 120px;

	@media #{$xs} {
		width: 80px;
	}

	img {
		width: 100%;
	}
}

.ms-news-widget {
	.ms-input2-box {
		input {
			padding-inline-end: 55px;
		}
	}
}

.ms-news4-cat {
	position: absolute;
	background: var(--clr-common-white);
	border-radius: 100px;
	color: var(--clr-text-10);
	font-weight: 500;
	height: 30px;
	line-height: 30px;
	padding: 0 16px;
	z-index: 2;
	top: 36px;
	inset-inline-start: 36px;

	@media #{$xxs} {
		top: 20px;
		inset-inline-start: 20px;
	}

	&:hover {
		color: var(--clr-text-10);
	}
}