#loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Center content vertically */
    background-color: black;
    z-index: 9999;
    padding: 20px 0;
    box-sizing: border-box;
  }

  .center {
    flex-grow: 1;
    /* Take up remaining space to center vertically */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center2 {
    flex-grow: 1;
    /* Take up remaining space to center vertically */
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #loading-screen img {
    width: 100px;
    /* Adjust the size of the logo */
  }

  #loading-screen img:last-child {
    margin-bottom: 20px;
    /* Add some margin to the bottom image */
  }

  /* From Uiverse.io by asgardOP */
  .loader {
    width: 5px;
    margin-left: 3px;
    height: 100px;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    background-color: #df581e;
    position: relative;
  }

  .l1 {
    animation: l1 1s 0s infinite ease;
  }

  .l2 {
    animation: l1 1s 0.1s infinite ease;
  }

  .l3 {
    animation: l1 1s 0.2s infinite ease;
  }

  .l4 {
    animation: l1 1s 0.3s infinite ease;
  }

  .l5 {
    animation: l1 1s 0.4s infinite ease;
  }

  .l6 {
    animation: l1 1s 0.5s infinite ease;
  }

  .l7 {
    animation: l1 1s 0.6s infinite ease;
  }
  .l8 {
    animation: l1 1s 0.6s infinite ease;
  }
  .l9 {
    animation: l1 1s 0.6s infinite ease;
  }
  .l10 {
    animation: l1 1s 0.6s infinite ease;
  }

  @keyframes l1 {
    0% {
      height: 100px;
    }

    50% {
      height: 10px;
      background-color: rgba(0, 190, 16, 0);
    }

    100% {
      height: 100px;
    }
  }