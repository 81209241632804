@use '../utils' as *;

/* ------------------------------------------ */
/*  15 - meanmenu css  */
/* ------------------------------------------ */

/* Mean menu customize */

.mean-container {
	.mean-bar {
		background: transparent;
	}

	.mean-nav {
		margin-top: 0;
		background: transparent;

		ul {
			li {
				a {
					border-top: 1px solid transparent;
					text-transform: capitalize;
					padding: 10px 0;
					@include transition(0.3s);

					&:hover {
						color: var(--clr-theme-1);
					}
				}
			}
		}

		&>ul {
			display: block !important;
		}
	}

	a.meanmenu-reveal {
		display: none !important;
	}

	.mean-expand.mean-clicked {
		transform: rotate(45deg);
	}
}

// mobile-menu
.mobile-menu {
	&.mean-container {
		overflow: hidden;

		.mean-bar {
			padding: 0;
			min-height: auto;
			background: none;
		}

		.mean-nav {
			background: none;
			margin-top: 0;

			ul {
				li {
					a {
						color: var(--clr-common-white);

						@include rtl {
							float: left;
							text-align: right;
						}

						&.mean-expand {
							width: 30px;
							height: 30px;
							text-align: center;
							position: absolute;
							inset-inline-end: 0;
							top: 20px;
							transform: translateY(-50%);
							background: transparent;
							font-size: 14px !important;
							border: 1px solid #414141 !important;
							line-height: 28px;
							padding: 0 !important;
							color: var(--clr-common-white);
							border-radius: 50%;

							&.mean-clicked {
								i {
									transform: rotate(45deg);
									-webkit-transform: rotate(45deg);
									-moz-transform: rotate(45deg);
									-ms-transform: rotate(45deg);
									-o-transform: rotate(45deg);
									color: var(--clr-common-white);
								}
							}

							@include rtl {
								right: auto;
								left: 0;
								text-align: center;
							}
						}

						&:hover {
							color: var(--clr-theme-1);
						}
					}
				}
			}

			>ul {
				padding: 0;
				margin: 0;
				width: 100%;
				list-style-type: none;
				display: block !important;

				>li {
					&:first-child {
						>a {
							border-top: 0;
						}
					}
				}
			}
		}

		a {
			&.meanmenu-reveal {
				display: none !important;
			}
		}
	}
}

.mean-container {
	.mean-nav {
		ul {
			li {
				a {
					width: 100%;
					padding: 10px 0;
					font-weight: 500;
					font-size: 16px;
					text-transform: inherit;

					&.mean-expand {
						&.mean-clicked {
							background: var(--clr-theme-1);
							color: var(--clr-common-white);
							border-color: var(--clr-theme-1) !important;
						}
					}
				}
			}
		}
	}
}

.mean-container .mean-nav ul li.dropdown-opened>a,
.mean-container .mean-nav ul li.dropdown-opened>span {
	color: var(--clr-theme-1);
}

// btn-style
.fill-btn {


	span {
		overflow: hidden;
		position: relative;
		top: 0;
		inset-inline-end: 0;

		&:after {
			position: absolute;
			content: "\f061";
			font-family: "Font Awesome 6 Pro";
			font-weight: 900;
			left: -20px;
			bottom: -20px;
			-webkit-transform: translate(0, 0) rotate(-45deg);
			transform: translate(0, 0) rotate(-45deg);
			opacity: 0;
			visibility: hidden;
			-webkit-transition: all 0.5s;
			transition: all 0.5s;
		}

		i {
			-webkit-transform: translate(0, 0) rotate(-45deg);
			transform: translate(0, 0) rotate(-45deg);
			-webkit-transition: all 0.5s;
			transition: all 0.5s;
		}
	}

	&:hover {
		span {
			&:after {
				opacity: 1;
				visibility: visible;
				-webkit-transform: translate(20px, -20px) rotate(-45deg);
				transform: translate(20px, -20px) rotate(-45deg);
			}

			i {
				-webkit-transform: translate(20px, -20px) rotate(-45deg);
				transform: translate(20px, -20px) rotate(-45deg);
			}
		}

		background: #d45500;
		color: var(--clr-common-white);
		border-color: #d45500;

	}
}